import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Skills = () => {
  return (
    <Layout>
      <SEO title="Technology Matrix" />
    </Layout>
  );
};
export default Skills;
